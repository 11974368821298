<template>
  <v-container
    style="background:rgba(255,255,255,0.6); position:absolute; top:0; bottom:0; left:0; right:0;"
  >
    <v-row no-gutters style="font-size:18px;padding:0; margin:70px 0 0 0;">
      <v-col cols="12">
        <div class="d-flex flex-column justify-center align-center">
          <p style="margin:0; padding:0; font-size:50px;">
            SanQua Ekspedisi
          </p>
        </div>
        <div id="clock" style="margin-top:100px;">
          <p class="date">{{ days }} {{ date }}</p>
          <p class="time">{{ time }}</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  name: 'expeditiondashboard',
  components: {},
  data: () => ({
    screenWidth: 0,
    screenHeight: 0,
    loading: false,
    date: '',
    time: '',
    days: '',
    week: ['MINGGU', 'SENIN', 'SELASA', 'RABU', 'KAMIS', 'JUMAT', 'SABTU'],
    isLocationSupported: false
  }),
  async mounted() {
    this.setArrTabs()
    this.screenWidth = screen.width
    this.screenHeight = screen.height
  },
  created() {
    setInterval(this.updateTime, 1000)
    this.updateTime()
  },
  methods: {
    ...mapMutations(['setTabs']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    setArrTabs() {
      const tabsArr = [
        { tab_name: 'Dashboard', path: '/expedition', isShown: false },
        {
          tab_name: 'Transaksi',
          path: '',
          sub_tabs: [
            {
              sub_title: 'Surat Jalan',
              path: '/expedition/ordertransaction'
            },
            {
              sub_title: 'Buat Surat Jalan',
              path: '/expedition/makeorder'
            }
          ],
          isShown: false
        },
        // {
        //   tab_name: 'Report',
        //   path: '',
        //   sub_tabs: [
        //     {
        //       sub_title: 'Realisasi Ritase',
        //       path: '/expedition/riterealization'
        //     },
        //     {
        //       sub_title: 'Realisasi Kuantitas',
        //       path: '/expedition/qtyrealization'
        //     }
        //   ],
        //   isShown: false
        // },
        {
          tab_name: 'Master Data',
          path: '',
          isShown: false,
          sub_tabs: [
            {
              sub_title: 'Kendaraan',
              path: '',
              sub_tabs: [
                {
                  sub_title: 'Kendaraan',
                  path: '/expedition/ms/vehicle'
                },
                {
                  sub_title: 'Jenis Kendaraan',
                  path: '/expedition/ms/vehicletype'
                },
                {
                  sub_title: 'Bahan Bakar',
                  path: '/expedition/ms/fueltype'
                }
              ]
            },
            {
              sub_title: 'Zona',
              path: '/expedition/ms/zone'
            },
            {
              sub_title: 'Produk',
              path: '/expedition/ms/product'
            },
            {
              sub_title: 'Lokasi',
              path: '',
              sub_tabs: [
                {
                  sub_title: 'Customer',
                  path: '/expedition/ms/customer'
                },
                {
                  sub_title: 'Sub Customer',
                  path: '/expedition/ms/subcustomer'
                },
                {
                  sub_title: 'Downline',
                  path: '/expedition/ms/downline'
                }
              ]
            },
            {
              sub_title: 'List Harga',
              path: '/expedition/ms/pricelist'
            },
            {
              sub_title: 'Ongkos Jalan',
              path: '/expedition/ms/travelcost'
            }
          ]
        }
      ]

      this.setTabs(tabsArr)
    },
    updateTime() {
      var cd = new Date()
      this.time =
        this.zeroPadding(cd.getHours(), 2) +
        ':' +
        this.zeroPadding(cd.getMinutes(), 2) +
        ':' +
        this.zeroPadding(cd.getSeconds(), 2)
      this.days = `${this.week[cd.getDay()]}`
      this.date = `${this.zeroPadding(cd.getDate(), 2)}-${this.zeroPadding(
        cd.getMonth() + 1,
        2
      )}-${this.zeroPadding(cd.getFullYear(), 4)}`
    },
    zeroPadding(num, digit) {
      var zero = ''
      for (var i = 0; i < digit; i++) {
        zero += '0'
      }
      return (zero + num).slice(-digit)
    },
    convertDate2(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = Intl.DateTimeFormat('id', {
          day: 'numeric',
          month: 'long'
        }).format(date)
        return local
      }
    }
  }
}
</script>
<style scoped lang="scss">
#clock {
  font-family: 'Share Tech Mono', monospace;
  // color: #ffffff;
  text-align: center;
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  color: #525252;
  font-weight: bold;
  text-shadow: 0 0 20px rgba(9, 175, 230, 9), 0 0 20px rgba(10, 175, 230, 0);
  .time {
    letter-spacing: 0.05em;
    font-size: 24px;
    padding: 5px 0;
    margin: 0;
  }
  .date {
    letter-spacing: 0.1em;
    font-size: 14px;
    margin: 0;
  }
}

@media (max-width: 576px) {
  #clock {
    .time {
      font-size: 3em;
    }
    .date {
      font-size: 1em;
    }
  }
}
@media (max-width: 320px) {
}
</style>
